* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

.gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 19% 31%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 70%, rgba(152, 221, 202, 0.83) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 19% 31%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 70%, rgba(152, 221, 202, 0.83) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 19% 31%, rgba(255, 255, 255, 1) 0%, #d5ecc2 70%, rgba(152, 221, 202, 0.83) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 19% 31%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 70%, rgba(152, 221, 202, 0.83) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 19% 31%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 70%, rgba(152, 221, 202, 0.83) 100%);

}
            

.gradient__text {
    background: var(--gradient-text);
    background-clip: text; 
    -webkit-background-clop: text; 
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 /* ----------------------------------------------
 * Generated by Animista on 2021-11-20 20:31:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- 
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* mid-size devices padding and margin */
@media screen and (max-width: 700px) {
   .section__padding {
       padding: 4rem;
   }
   
   .section__margin {
       margin: 4rem; 
   }
}

/* mobile device padding and margin */
@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}
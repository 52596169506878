.lp__whatintrinsico {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);


    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 87% 150%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 30%, rgba(152, 221, 202, 0.83) 100%);
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 87% 150%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 30%, rgba(152, 221, 202, 0.83) 100%);
    
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 87% 150%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 30%, rgba(152, 221, 202, 0.83) 100%);
    
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 87% 150%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 30%, rgba(152, 221, 202, 0.83) 100%);
    
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 87% 150%, rgba(255, 255, 255, 1) 0%, rgba(213, 236, 194, 1) 30%, rgba(152, 221, 202, 0.83) 100%);


                
}

/* customize the first row, which contains the what is lp title and explanation */
.lp__whatintrinsico-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.lp__whatintrinsico-feature .lp__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.lp__whatintrinsico-feature .lp__features-container_feature-text {
    max-width: 700px;
}

/* -------------customize the middle row */
.lp__whatinstrinsico-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.lp__whatintrinsico-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.lp__whatintrinsico-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

/* customize the posititoning of the features in the last row */
.lp__whatintrinsico-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.lp__whatintrinsico-container .lp__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.lp__whatintrinsico-container .lp__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .lp__whatintrinsico-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .lp__whatintrinsico-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .lp__whatintrinsico-feature .lp__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .lp__whatintrinsico-feature .lp__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .lp__whatintrinsico-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .lp__whatintrinsico-container .lp__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
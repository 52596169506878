.lp__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.lp__navbar-links {
    flex: 1; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
} 

.lp__navbar-links_logo {
    margin-right: 2rem;
} 

.lp__navbar-links_logo img {
    width: 62.56px;
    height: 45px; 
}

.lp__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.lp__navbar-links_container a {
    text-decoration: none;
}

/* places the sign in option in a single row */
.lp__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* adds a font settings to items in these containers
    this includes distancing out navbar links
*/
.lp__navbar-links_container p, 
.lp__navbar-links_container a,
.lp__navbar-sign p, 
.lp__navbar-menu_container p {
    color: #eb1f18;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.lp__navbar-sign button,
.lp__navbar-menu_container button {
    color: white;
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    background: #FF4820;
    border-radius: 5px;    

    border: none;
    outline: none;
    cursor: pointer;
}

/* hides the navbar 3 line menu button */
.lp__navbar-menu {
    margin-left: 1rem;
    display: none; 
    position: relative;
}

/* when you hover on the navbar 3line menu, the cursor becomes a pointer */
.lp__navbar-menu svg {
    cursor: pointer;
}

.lp__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

/* then 3line menu will give its p tags 1 rem margin */
.lp__navbar-menu_container p {
    margin: 1rem 0;
}

/* hide the sign in sign up section thats in the 3 line menu */
.lp__navbar-menu_container-links-sign {
    display: none;
}

/* the device being used here will determine what is shown */
@media  screen and (max-width: 1050px) {
   .lp__navbar-links_container {
       display: none;
   } 

   .lp__navbar-menu {
       display: flex;
   }
}

@media screen and (max-width: 700px) {
    .lp__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .lp__navbar {
        padding: 2rem;
    }

    .lp__navbar-sign {
        display: none;
    }

    .lp__navbar-menu_container {
        top: 20px;
    }

    .lp__navbar-menu_container-links-sign {
        display: block;
    }
}